<template>
  <div
    class="flex items-center space-x-2 cursor-pointer p-1-5 rounded-md"
    :class="modelValue ? 'bg-primary bg-opacity-10' : 'hover:bg-gray-50'"
    data-cy="log-comments-toggle"
    @click="toggleCommentsSectionVisibility"
  >
    <icon-base
      :icon="IconComment"
      :fill="modelValue ? UTIL_COLORS.PRIMARY : undefined"
    />

    <div
      class="flex items-center justify-center text-center font-semibold rounded-full w-auto px-1-5 text-xs pill transform translate-y-0-25"
      :class="
        modelValue ? 'bg-primary text-white' : 'bg-gray-200 text-gray-600'
      "
    >
      {{ count }}
    </div>
  </div>
</template>

<script setup lang="ts">
import IconComment from "@/components/icons/IconComment.vue";
import { UTIL_COLORS } from "@/helpers/constants";

const emit = defineEmits<{
  "update:modelValue": [boolean];
}>();

const props = defineProps({
  count: {
    type: Number
  },
  modelValue: {
    type: Boolean,
    required: true
  }
});

const toggleCommentsSectionVisibility = () => {
  emit("update:modelValue", !props.modelValue);
};
</script>
